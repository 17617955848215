import React, { useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Grid, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import AdminTable from 'src/components/admin-table/admin-table';
import Dropdown, { Option } from 'src/components/dropdown/dropdown';
import EmptyState from 'src/components/empty-state/empty-state';
import ExpansionList from 'src/components/expansion-list/expansion-list';
import StatusLabel, { LabelVariety } from 'src/components/status-label/status-label';
import {
  ControlProps,
  localSort,
  PaginationVariables,
  SortVariables,
} from 'src/components/table-controls/table-controls';
import { getClientConfig } from 'src/config/config';
import { PackageYear } from 'src/graphql-types/lta-registration/globalTypes';
import {
  listMembershipPackage as ListMembershipPackage,
  listMembershipPackageVariables as ListMembershipPackageVariables,
} from 'src/graphql-types/lta-registration/listMembershipPackage';
import { firstCapital } from 'src/utils/helper/membership';
import {
  clearStorage,
  retrieveRowsPerPage,
  retrieveSelectedYear,
  storeSelectedYear,
} from 'src/utils/storage/local-storage';

import { DataCols, H4, PageMaxWidth, Panel, Spinner } from '@clubspark-react/clubspark-react-tools';

import { LIST_MEMBERSHIP_PACKAGES } from './lta-memberships-queries';
import * as styles from './lta-memberships.module.less';

export interface LTAMembership {
  id: string;
  name: string;
  type: string;
  status: string;
  code: string;
  organisationType: string;
  startDate: string;
  endDate: string;
  renewsOn: string;
  packageYear: number;
}

export const LTAMemberships: React.FC = () => {
  const hiddenSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const hiddenOnlyXs = useMediaQuery((theme) => theme.breakpoints.only('xs'));
  const { membershipYearOptions } = getClientConfig();
  const storedRowsPerPage = useMemo(() => retrieveRowsPerPage(), []);
  const [dataToDisplay, setDataToDisplay] = useState<any[]>();
  const [allData, setAllData] = useState<any[]>();
  const [selectedYear, setSelectedYear] = useState<PackageYear>(PackageYear[retrieveSelectedYear()]);

  const { data, loading, error } = useQuery<ListMembershipPackage, ListMembershipPackageVariables>(
    LIST_MEMBERSHIP_PACKAGES,
    {
      fetchPolicy: 'no-cache',
      variables: {
        packageYear: selectedYear,
      },
    },
  );

  useEffect(() => {
    if (data) {
      const newData = data?.listMembershipPackage.filter((packageInfo) => packageInfo.packageYear !== 2019);
      setAllData(newData);
      setDataToDisplay(newData.slice(0, 0 + (storedRowsPerPage ?? 10)));
    }
    if (error) {
      clearStorage();
      // navigate('/')
    }
  }, [data, error]);

  const onControlChange = (controls: { pagination?: PaginationVariables; sorting?: SortVariables }) => {
    let dataToSort = allData?.slice(
      controls.pagination?.offset,
      (controls.pagination?.limit ?? 10) + (controls.pagination?.offset ?? 0),
    );

    if (controls.sorting) {
      dataToSort = localSort(dataToSort, controls.sorting.orderBy, controls.sorting.order);
    }

    // onControlChange triggers on every rerender, so we need to set
    // state only if object differs to avoid loop.
    if (JSON.stringify(dataToDisplay) !== JSON.stringify(dataToSort)) {
      setDataToDisplay(dataToSort);
    }
  };

  const controls: ControlProps = {
    onControlChange,
    paginaitonDisabled: allData ? allData?.length < 10 : false,
    sortDisabled: false,
    totalItems: allData?.length,
  };
  const { t } = useTranslation();

  const headers: DataCols<LTAMembership> = useMemo(
    () => [{ key: 'title', title: t('title'), getValue: (m) => m.name }],
    [t],
  );

  const handleOnClickTableRow = (t: LTAMembership, event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    navigate(`/lta-memberships/${t.id}`);
  };

  const handleExpansionListClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, t: LTAMembership) => {
    navigate(`/lta-memberships/${t.id}`);
  };

  const cols: DataCols<LTAMembership> = useMemo(
    () => [
      { key: 'name', title: t('Package name'), getValue: (m) => m.name },
      { key: 'code', title: t('code'), getValue: (m) => m.code },
      {
        key: 'type',
        title: t('type'),
        getValue: (m) => firstCapital(m.type ?? ''),
      },
      {
        key: 'start',
        title: t('start'),
        getValue: (m) => t('memberships dates', { date: dayjs.utc(m.startDate).format('DD/MM/YYYY') }),
      },
      {
        key: 'end',
        title: t('end'),
        getValue: (m) => t('memberships dates', { date: dayjs.utc(m.renewsOn).format('DD/MM/YYYY') }),
      },
      {
        key: 'status',
        title: t('status'),
        getValue: (m) => (
          <StatusLabel variety={getStatusColor(m.status ?? '')}>{firstCapital(m.status ?? '')}</StatusLabel>
        ),
      },
    ],
    [t],
  );

  const getStatusColor = (status: string): LabelVariety => {
    switch (status) {
      case 'PUBLIC':
        return 'success';
      case 'PRIVATE':
        return 'warning';
      default:
        return 'neutral';
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <PageMaxWidth>
      <Panel>
        <Grid container alignItems="center" justifyContent="space-between" className={styles.tableHeader}>
          <H4>{t('all memberships')}</H4>
          <Dropdown
            selected={selectedYear}
            options={membershipYearOptions as Option[]}
            onSelect={(option) => {
              setSelectedYear(option.value);
              storeSelectedYear(option.value);
            }}
          />
        </Grid>
        {allData?.length === 0 ? (
          <EmptyState title="There are no memberships" icon="lg-empty-state" />
        ) : (
          <>
            {hiddenOnlyXs ? null : (
              <AdminTable
                columns={cols}
                data={dataToDisplay}
                onRowClick={handleOnClickTableRow}
                loading={loading}
                rowsPerPageExpanded
                // error={error}
                controls={controls}
              />
            )}
            {hiddenSmUp ? null : (
              <ExpansionList
                columns={cols}
                data={dataToDisplay ?? []}
                controls={controls}
                loading={loading}
                handleClick={handleExpansionListClick}
                headers={headers}
                actions
              />
            )}
          </>
        )}
      </Panel>
    </PageMaxWidth>
  );
};
