import React from 'react';

import Layout from 'src/components/layout/layout';
import { LTAMemberships } from 'src/components/lta-memberships/lta-memberships';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="LTA Memberships" />
      <PrivateRoute>
        <LTAMemberships />
      </PrivateRoute>
    </Layout>
  );
};

export default IndexPage;
